import { useDispatch, useSelector } from 'react-redux';
import jsCookie from 'js-cookie';
import { useEffect } from 'react';
import { getProfile } from 'store/auth';

export default function useAuth(typeUser) {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const accessToken = auth[typeUser]?.accessToken;
    const token = {
        admin: jsCookie.get('SID'),
        customer: jsCookie.get('SID_CLIENT')
    };

    const isAuthenticated = Boolean(accessToken) || Boolean(token[typeUser]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getProfile(typeUser));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return {
        accessToken: accessToken || token[typeUser],
        isAuthenticated
    };
}
