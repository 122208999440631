import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    listOfPatrun: {
        count: 0,
        results: []
    }
};

const patrunSlice = createSlice({
    name: 'patrun',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getPatrunSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfPatrun = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = patrunSlice.actions;

export function getPatruns(params) {
    return async (dispatch) => {
        const url = endpoints.PATTERN;
        const config = {
            method: 'get',
            params
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getPatrunSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export default patrunSlice.reducer;
