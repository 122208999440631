import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfColor: {
        count: 0,
        results: []
    },
    viewColor: null
};

const colorSlice = createSlice({
    name: 'color',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getColorsSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfColor = action.payload;
        },
        getColorSuccess: (state, action) => {
            state.isLoading = false;
            state.viewColor = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = colorSlice.actions;

export function getColors(params) {
    return async (dispatch) => {
        const url = endpoints.COLOR;
        const config = {
            method: 'get',
            params
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getColorsSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function getColor(colorId) {
    return async (dispatch) => {
        const url = `${endpoints.COLOR + colorId}/`;
        const config = {
            method: 'get'
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getColorSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function createColor(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.COLOR;
        const config = {
            method: 'post',
            data
        };

        dispatch(actions.startLoading());

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Color berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Master Color'
            });
            navigate('/admin/master-data/color', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function ubahColor(colorId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.COLOR + colorId}/`;
        const config = {
            method: 'put',
            data
        };

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Color berhasil diubah',
                buttonText: 'Kembali ke halaman Master Color'
            });
            navigate('/admin/master-data/color', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function removeColor(colorId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.COLOR + colorId}/`;
        const config = {
            method: 'delete'
        };

        try {
            await axios(url, config);
            callback('Coating berhasil dihapus', null);
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export default colorSlice.reducer;
