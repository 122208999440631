import axios from 'axios';
import Cookies from 'js-cookie';
import showMessagePopUp from 'ui-component/messagePopUp';
import token, { withBearer, customerToken, customerWithBearer } from './token';
import map from 'lodash/map';
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    return response.data;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
    if (response?.status >= 200 && response?.status < 300) {
        return response;
    }

    const error = new Error(response?.status);
    error.response = response;
    throw error;
}

function handleError(error) {
    const { response } = error;

    if (response?.status === 401) {
        let { message } = response.data;

        if (!message) {
            message = 'Unauthorized';
        }

        showMessagePopUp({
            visible: true,
            type: 'error',
            message
        });

        setTimeout(() => {
            Cookies.remove('SID');
            window.location.replace('/admin/auth');
        }, 500);
    }

    if (response?.status === 500) {
        showMessagePopUp({
            visible: true,
            type: 'error',
            message: 'Internal server error'
        });
    }

    // if (response?.status === 400) {
    //     const errors = response?.data;
    //     const message = Object.keys(errors)
    //         .map((item) => `${item}: ${errors[item]} /n`)
    //         .join(',');

    //     showMessagePopUp({
    //         visible: true,
    //         type: 'error',
    //         message
    //     });
    // }

    if (response?.status === 422 && response.data?.errors) {
        let { detail: message } = response.data;

        message = map(response.data.errors, (a) => a.join()).join(' & ');

        showMessagePopUp({
            visible: true,
            type: 'error',
            message
        });
    }

    if (response?.data?.detail) {
        showMessagePopUp({
            visible: true,
            type: 'error',
            message: response?.data?.detail
        });
    }

    throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options = {}, withoutParse = false) {
    // axios.interceptors.response.use(undefined, error => {
    //   // eslint-disable-next-line no-param-reassign
    //   error.handleGlobally = handleError(error, url);

    //   return Promise.reject(error);
    // });

    axios.interceptors.response.use(
        (response) => response,
        (error) => handleError(error)
    );

    const isAdmin = window.location.pathname.includes('/admin');
    const isCustomer = window.location.pathname.includes('/customer');

    if (token && !options?.headers?.Authorization && isAdmin) {
        // eslint-disable-next-line no-param-reassign
        options.headers = Object.assign(options.headers || {}, {
            Authorization: withBearer
        });
    }

    if (customerToken && !options?.headers?.Authorization && isCustomer) {
        // eslint-disable-next-line no-param-reassign
        options.headers = Object.assign(options.headers || {}, {
            Authorization: customerWithBearer
        });
    }

    if (withoutParse) {
        return axios(url, options).then(checkStatus);
        // .catch(handleError);
    }

    return axios(url, options).then(checkStatus).then(parseJSON);
    // .catch(handleError);
}
