import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';
import standardParams from 'utils/standardParams';

const initialState = {
    isLoading: false,
    error: null,
    listOfOrder: {
        count: 0,
        data: []
    },
    viewOrder: {
        status: 'ON_REVIEW'
    },
    createOrder: {
        isLoading: false,
        successed: false
    },
    updateOrder: {
        isLoading: false,
        successed: false
    }
};

const orderSlice = createSlice({
    name: 'customer-order',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        startUpdateOrder: (state) => {
            state.updateOrder.isLoading = true;
            state.updateOrder.successed = false;
        },
        updateOrderSuccess: (state) => {
            state.updateOrder.isLoading = false;
            state.updateOrder.successed = true;
        },
        updateOrderFail: (state) => {
            state.updateOrder.isLoading = false;
            state.updateOrder.successed = false;
        },
        createOrderLoading: (state) => {
            state.createOrder.isLoading = true;
            state.createOrder.successed = false;
        },
        createOrderSuccess: (state) => {
            state.createOrder.isLoading = false;
            state.createOrder.successed = true;
        },
        createOrderFail: (state) => {
            state.createOrder.isLoading = false;
            state.createOrder.successed = false;
        },
        getOrdersSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfOrder = action.payload;
        },
        getViewOrderSuccess: (state, action) => {
            state.isLoading = false;
            state.viewOrder = action.payload;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export function getOrders(params) {
    return async (dispatch) => {
        const url = endpoints.ORDER;
        const config = {
            method: 'get',
            params
        };

        dispatch(orderSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(orderSlice.actions.getOrdersSuccess(response));
        } catch (error) {
            dispatch(orderSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function getViewOrder(orderId) {
    return async (dispatch) => {
        const url = `${endpoints.ORDER}${orderId}/`;
        const config = {
            method: 'get'
        };

        dispatch(orderSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(orderSlice.actions.getViewOrderSuccess(response));
        } catch (error) {
            dispatch(orderSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function createOrder(data, navigate, handleClose, path = null) {
    return async (dispatch) => {
        const url = endpoints.ORDER;
        const config = {
            method: 'post',
            data
        };

        dispatch(orderSlice.actions.createOrderLoading());

        try {
            await axios(url, config);
            dispatch(orderSlice.actions.createOrderSuccess());
            handleClose();
            if (path) {
                navigate('/admin/orders', { replace: true });
            } else {
                navigate('/customer/orders', { replace: true });
            }
        } catch (error) {
            handleClose();
            dispatch(orderSlice.actions.createOrderFail());
            if (error?.response?.status === 400) {
                const errors = error?.response?.data;
                const message = Object.keys(errors)
                    .map((item) => `<p>${standardParams?.[item]}: ${errors[item]}</p>`)
                    .join(',');

                showMessagePopUp({
                    visible: true,
                    type: 'error',
                    message
                });
            }
        }
    };
}

export function updateOrder(orderId, data, fallback) {
    return async (dispatch) => {
        const url = `${endpoints.ORDER}${orderId}/`;
        const config = {
            method: 'put',
            data
        };

        dispatch(orderSlice.actions.startUpdateOrder());

        try {
            const { data: response } = await axios(url, config);
            dispatch(orderSlice.actions.updateOrderSuccess());
            fallback(response, null);

            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            fallback(null, error?.response?.data);
            dispatch(orderSlice.actions.updateOrderFail());

            if (error?.response?.status === 400) {
                const errors = error?.response?.data;
                const message = Object.keys(errors)
                    .map((item) => `<p>${standardParams?.[item]}: ${errors[item]}</p>`)
                    .join(',');

                showMessagePopUp({
                    visible: true,
                    type: 'error',
                    message
                });
            }
        }
    };
}

export const actions = orderSlice.actions;

export default orderSlice.reducer;
