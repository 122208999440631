import { useRoutes } from 'react-router-dom';

// routes
import AdminRoutes from './AdminRoutes';
import CustomerRoutes from './CustomerRoutes';
import ClientRoutes from './ClientRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AdminRoutes, CustomerRoutes, ClientRoutes], config.basename);
}
