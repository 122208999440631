import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import endpoints from 'utils/endpoints';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    isDeleteLoading: false,
    error: null,
    listOfOptikGroup: {
        count: 0,
        results: []
    },
    listOptikChild: [],
    viewOptik: null
};

export const optikSlice = createSlice({
    name: 'admin-optik-group',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        setListOfOptik: (state, action) => {
            if (action.payload.isInfinite) {
                state.listOfOptikGroup.results = state.listOfOptikGroup.results.concat(...action.payload.data.result);
                state.listOfOptikGroup.count = action.payload.data.count;
                state.listOfOptikGroup.next = action.payload.data.next;
            } else {
                state.listOfOptikGroup = action.payload.data;
            }
            state.isLoading = false;
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
        getOptikViewSuccess: (state, action) => {
            state.viewOptik = action.payload;
            state.isLoading = false;
        },
        getOptikChildSuccess: (state, action) => {
            state.listOptikChild = action.payload;
            state.isLoading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export function getOptik(params) {
    return async (dispatch) => {
        const url = endpoints.OPTIK_GROUP;
        const config = {
            method: 'get',
            params
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(optikSlice.actions.setListOfOptik({ data: response, isInfinite: params?.infinite }));
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function getOptikView(optikId) {
    return async (dispatch) => {
        const url = endpoints.OPTIK_GROUP + optikId;
        const config = {
            method: 'get'
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(optikSlice.actions.getOptikViewSuccess(response));
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function getOptikChild(groupId) {
    return async (dispatch) => {
        const url = endpoints.OPTIK_GROUP_CHILD(groupId);
        const config = {
            method: 'get'
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(optikSlice.actions.getOptikChildSuccess(response));
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function createOptik(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.OPTIK_GROUP;
        const config = {
            method: 'post',
            data
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            await axios(url, config);
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Optik Parent berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Optik Parent'
            });
            await navigate('/admin/master-data/optik-group', { replace: true });
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function ubahOptik(optikId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.OPTIK_GROUP + optikId}/`;
        const urlUpdate = `${endpoints.OPTIK_GROUP_CHILD(optikId)}/update/`;
        const config = {
            method: 'put',
            data
        };

        const formData = new FormData();

        data?.optik?.forEach((i) => {
            formData.append('optik', i);
        });

        try {
            await axios(url, config);
            await axios(urlUpdate, { method: 'POST', data: formData });
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Optik Parent berhasil diubah',
                buttonText: 'Kembali ke halaman Optik Parent'
            });
            await navigate('/admin/master-data/optik-group', { replace: true });
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function removeOptik(optikId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.OPTIK_GROUP}${optikId}/`;
        const config = {
            method: 'delete'
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            await axios(url, config);
            callback('Optik Parent berhasil dihapus', null);
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export const actions = optikSlice.actions;
export default optikSlice.reducer;
