import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfCoating: {
        count: 0,
        results: []
    },
    viewCoating: null
};

const coatingSlice = createSlice({
    name: 'coating',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getCoatingsSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfCoating = action.payload;
        },
        getCoatingSuccess: (state, action) => {
            state.isLoading = false;
            state.viewCoating = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = coatingSlice.actions;

export function getCoatings(params) {
    return async (dispatch) => {
        const url = endpoints.COATING;
        const config = {
            method: 'get',
            params
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getCoatingsSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function getCoating(coatingId) {
    return async (dispatch) => {
        const url = `${endpoints.COATING + coatingId}/`;
        const config = {
            method: 'get'
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getCoatingSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function createCoating(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.COATING;
        const config = {
            method: 'post',
            data
        };

        dispatch(actions.startLoading());

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Coating berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Master Coating'
            });
            navigate('/admin/master-data/coating', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function ubahCoating(coatingId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.COATING + coatingId}/`;
        const config = {
            method: 'put',
            data
        };

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Coating berhasil diubah',
                buttonText: 'Kembali ke halaman Master Coating'
            });
            navigate('/admin/master-data/coating', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function removeCoating(coatingId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.COATING + coatingId}/`;
        const config = {
            method: 'delete'
        };

        try {
            await axios(url, config);
            callback('Coating berhasil dihapus', null);
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export default coatingSlice.reducer;
