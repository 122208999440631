import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import endpoints from 'utils/endpoints';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    isDeleteLoading: false,
    error: null,
    listOfOptik: {
        count: 0,
        results: []
    },
    viewOptik: null
};

export const optikSlice = createSlice({
    name: 'admin-optik',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        setListOfOptik: (state, action) => {
            if (action.payload.isInfinite) {
                state.listOfOptik.results = state.listOfOptik.results.concat(...action.payload.data.result);
                state.listOfOptik.count = action.payload.data.count;
                state.listOfOptik.next = action.payload.data.next;
            } else {
                state.listOfOptik = action.payload.data;
            }
            state.isLoading = false;
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
        getOptikViewSuccess: (state, action) => {
            state.viewOptik = action.payload;
            state.isLoading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export function getOptik(params) {
    return async (dispatch) => {
        const url = endpoints.OPTIK;
        const config = {
            method: 'get',
            params
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(optikSlice.actions.setListOfOptik({ data: response, isInfinite: params?.infinite }));
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function getOptikView(optikId) {
    return async (dispatch) => {
        const url = endpoints.OPTIK + optikId;
        const config = {
            method: 'get'
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(optikSlice.actions.getOptikViewSuccess(response));
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function createOptik(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.OPTIK;
        const config = {
            method: 'post',
            data
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            await axios(url, config);
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Optik berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Optik'
            });
            await navigate('/admin/master-data/optik', { replace: true });
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function ubahOptik(optikId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.OPTIK + optikId}/`;
        const config = {
            method: 'put',
            data
        };

        try {
            await axios(url, config);
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Optik berhasil diubah',
                buttonText: 'Kembali ke halaman Optik'
            });
            await navigate('/admin/master-data/optik', { replace: true });
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
        }
    };
}

export function removeOptik(optikId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.OPTIK}${optikId}/`;
        const config = {
            method: 'delete'
        };

        dispatch(optikSlice.actions.startLoading());

        try {
            await axios(url, config);
            callback('Coating berhasil dihapus', null);
        } catch (error) {
            dispatch(optikSlice.actions.setError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export const actions = optikSlice.actions;
export default optikSlice.reducer;
