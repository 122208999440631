// assets
import { IconDashboard, IconUser, IconBuildingStore, IconDatabase, IconFileUpload } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconUser, IconBuildingStore, IconDatabase, IconFileUpload };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'MENU UTAMA',
    type: 'group',
    children: [
        {
            id: 'create',
            title: 'New Order',
            type: 'item',
            url: '/customer/orders/create',
            icon: icons.IconFileUpload,
            breadcrumbs: true
        },
        {
            id: 'orders',
            title: 'Order Management',
            type: 'item',
            url: '/customer/orders',
            icon: icons.IconBuildingStore,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
