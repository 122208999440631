import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import endpoints from 'utils/endpoints';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfCustomer: {
        count: 0,
        results: []
    },
    viewCustomer: null
};

export const customerSlice = createSlice({
    name: 'admin-user-customer',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getUsersSuccess: (state, action) => {
            state.listOfCustomer = action.payload;
            state.isLoading = false;
        },
        getUserSuccess: (state, action) => {
            state.viewCustomer = action.payload;
            state.isLoading = false;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export function getUsers(params) {
    return async (dispatch) => {
        const url = endpoints.USER;
        const config = {
            method: 'get',
            params
        };

        dispatch(customerSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(customerSlice.actions.getUsersSuccess(response));
        } catch (error) {
            dispatch(customerSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function getUser(userId) {
    return async (dispatch) => {
        const url = `${endpoints.USER + userId}/`;
        const config = {
            method: 'get'
        };

        dispatch(customerSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(customerSlice.actions.getUserSuccess(response));
        } catch (error) {
            dispatch(customerSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function createCustomer(data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.USER}create/`;
        const config = {
            method: 'post',
            data
        };

        dispatch(customerSlice.actions.startLoading());

        try {
            await axios(url, config);
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Admin berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Admin'
            });
            dispatch(customerSlice.actions.stopLoading());
            navigate('/admin/master-data/account-optik');
        } catch (error) {
            dispatch(customerSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function updateCustomer(adminId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.USER}${adminId}/`;
        const config = {
            method: 'put',
            data
        };

        dispatch(customerSlice.actions.startLoading());

        try {
            await axios(url, config);
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Admin berhasil diubah',
                buttonText: 'Kembali ke halaman Admin'
            });
            dispatch(customerSlice.actions.stopLoading());
            navigate('/admin/master-data/account-optik');
        } catch (error) {
            dispatch(customerSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function deleteCustomer(userId) {
    return async (dispatch) => {
        const url = `${endpoints.USER + userId}/`;
        const config = {
            method: 'delete'
        };

        dispatch(customerSlice.actions.startLoading());

        try {
            await axios(url, config);
            dispatch(getUsers());
        } catch (error) {
            dispatch(customerSlice.actions.hasError(error?.response?.data));
        }
    };
}

export const actions = customerSlice.actions;

export default customerSlice.reducer;
