import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import endpoints from 'utils/endpoints';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfLensa: {
        count: 0,
        results: []
    },
    listOfColors: {
        count: 0,
        results: []
    },
    listOfDensity: [],
    listOfCorridor: [],
    listOfTinting: [],
    viewLensa: null
};

export const lensaSlice = createSlice({
    name: 'lensa',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getLensaSuccess: (state, action) => {
            state.isLoading = false;
            if (action.payload.isInfinite) {
                state.listOfLensa.results = state.listOfLensa.results.concat(...action.payload.data.results);
                state.listOfLensa.count = action.payload.data.count;
                state.listOfLensa.next = action.payload.data.next;
            } else {
                state.listOfLensa = action.payload.data;
            }
        },
        getColorsListSuccess: (state, action) => {
            state.listOfColors.count = action.payload.length;
            state.listOfColors.results = action.payload;
        },
        getDensityListSuccess: (state, action) => {
            state.listOfDensity = action.payload;
        },
        getCorridorListSuccess: (state, action) => {
            state.listOfCorridor = action.payload;
        },
        getTintingListSuccess: (state, action) => {
            state.listOfTinting = action.payload;
        },
        getViewLensaSuccess: (state, action) => {
            state.isLoading = false;
            state.viewLensa = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = lensaSlice.actions;

export function getTinting() {
    return async (dispatch) => {
        const url = endpoints.TINTING_GROUP;
        const config = {
            method: 'get'
        };

        try {
            const response = await axios(url, config);
            dispatch(actions.getTintingListSuccess(response.results));
        } catch (error) {
            dispatch(actions.hasError(error));
        }
    };
}

export function getCorridor() {
    return async (dispatch) => {
        const url = endpoints.CORRIDOR;
        const config = {
            method: 'get',
            params: {
                limit: 100
            }
        };

        try {
            const response = await axios(url, config);
            dispatch(lensaSlice.actions.getCorridorListSuccess(response?.results));
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error));
        }
    };
}

export function getColorByTintingId(lensaId) {
    return async (dispatch) => {
        const url = `${endpoints.LENSA}${lensaId}/tinting_colors/`;
        const config = {
            method: 'get'
        };

        try {
            const response = await axios(url, config);
            dispatch(lensaSlice.actions.getColorsListSuccess(response));
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function getDensityByTintingId(tintingId) {
    return async (dispatch) => {
        const url = `${endpoints.TINTING_GROUP + tintingId}/`;
        const config = {
            method: 'get'
        };

        try {
            const response = await axios(url, config);
            dispatch(lensaSlice.actions.getDensityListSuccess(response));
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function getLensa(params) {
    return async (dispatch) => {
        const url = endpoints.LENSA;
        const config = {
            method: 'get',
            params
        };

        dispatch(lensaSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(lensaSlice.actions.getLensaSuccess({ data: response, isInfinite: params?.infinite }));
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function getViewLensa(lensaId) {
    return async (dispatch) => {
        const url = `${endpoints.LENSA + lensaId}/`;
        const config = {
            method: 'get'
        };

        dispatch(lensaSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(lensaSlice.actions.getViewLensaSuccess(response));
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function createLensa(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.LENSA;
        const config = {
            method: 'post',
            data
        };

        dispatch(lensaSlice.actions.startLoading());

        try {
            await axios(url, config);
            dispatch(lensaSlice.actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Lensa berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Master Lensa'
            });
            navigate('/admin/master-data/lensa', { replace: true });
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function updateLensa(data, lensaId, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.LENSA + lensaId}/`;
        const config = {
            method: 'put',
            data
        };

        dispatch(lensaSlice.actions.startLoading());

        try {
            await axios(url, config);
            dispatch(lensaSlice.actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Lensa berhasil diubah',
                buttonText: 'Kembali ke halaman Master Lensa'
            });
            navigate('/admin/master-data/lensa', { replace: true });
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function deleteLensa(lensaId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.LENSA + lensaId}/`;
        const config = {
            method: 'delete'
        };

        dispatch(lensaSlice.actions.startLoading());

        try {
            await axios(url, config);
            callback('Coating berhasil dihapus', null);
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export default lensaSlice.reducer;
