import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import endpoints from 'utils/endpoints';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfCustomer: {
        count: 0,
        results: []
    },
    viewCustomer: null
};

export const adminSlice = createSlice({
    name: 'admin-user',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getUsersSuccess: (state, action) => {
            state.listOfCustomer = action.payload;
            state.isLoading = false;
        },
        getUserSuccess: (state, action) => {
            state.viewCustomer = action.payload;
            state.isLoading = false;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export function getUsers(params) {
    return async (dispatch) => {
        const url = endpoints.USER;
        const config = {
            method: 'get',
            params
        };

        dispatch(adminSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(adminSlice.actions.getUsersSuccess(response));
        } catch (error) {
            dispatch(adminSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function getUser(userId) {
    return async (dispatch) => {
        const url = `${endpoints.USER + userId}/`;
        const config = {
            method: 'get'
        };

        dispatch(adminSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(adminSlice.actions.getUserSuccess(response));
        } catch (error) {
            dispatch(adminSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function createUser(data, navigate, reset) {
    return async (dispatch) => {
        const url = `${endpoints.USER}create/`;
        const config = {
            method: 'post',
            data
        };

        dispatch(adminSlice.actions.startLoading());

        try {
            await axios(url, config);
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Admin berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Admin'
            });
            dispatch(adminSlice.actions.stopLoading());
            reset();
            navigate('/admin/manage-admin');
        } catch (error) {
            dispatch(adminSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function updateUser(adminId, data, navigate, reset) {
    return async (dispatch) => {
        const url = `${endpoints.USER}${adminId}/`;
        const config = {
            method: 'put',
            data
        };

        dispatch(adminSlice.actions.startLoading());

        try {
            await axios(url, config);
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Admin berhasil diubah',
                buttonText: 'Kembali ke halaman Admin'
            });
            dispatch(adminSlice.actions.stopLoading());
            reset();
            navigate('/admin/manage-admin');
        } catch (error) {
            dispatch(adminSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function changePassword(data, callback) {
    return async (dispatch) => {
        const url = `${endpoints.USER}change-password/`;
        const config = {
            method: 'POST',
            data
        };

        dispatch(adminSlice.actions.startLoading());

        try {
            await axios(url, config);

            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Password berhasil diubah',
                buttonText: 'Kembali ke halaman Profile'
            });
            dispatch(adminSlice.actions.stopLoading());
            callback(null);
        } catch (error) {
            callback(error);
            dispatch(adminSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function deleteUser(userId) {
    return async (dispatch) => {
        const url = `${endpoints.USER + userId}/`;
        const config = {
            method: 'delete'
        };

        dispatch(adminSlice.actions.startLoading());

        try {
            await axios(url, config);
            dispatch(
                getUsers({
                    is_superuser: false,
                    is_group_admin: true,
                    is_customer: false
                })
            );
        } catch (error) {
            dispatch(adminSlice.actions.hasError(error?.response?.data));
        }
    };
}

export const actions = adminSlice.actions;

export default adminSlice.reducer;
