import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfDensity: {
        count: 0,
        results: []
    },
    viewDensity: null
};

const densitySlice = createSlice({
    name: 'density',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getColorsSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfDensity = action.payload;
        },
        getColorSuccess: (state, action) => {
            state.isLoading = false;
            state.viewDensity = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = densitySlice.actions;

export function getDensity(params) {
    return async (dispatch) => {
        const url = endpoints.DENSITY;
        const config = {
            method: 'get',
            params
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getColorsSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function getDensityById(colorId) {
    return async (dispatch) => {
        const url = `${endpoints.DENSITY + colorId}/`;
        const config = {
            method: 'get'
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getColorSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function createDensity(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.DENSITY;
        const config = {
            method: 'post',
            data
        };

        dispatch(actions.startLoading());

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Density berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Master Density'
            });
            navigate('/admin/master-data/tinting-density', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function ubahDensity(colorId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.DENSITY + colorId}/`;
        const config = {
            method: 'put',
            data
        };

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Density berhasil diubah',
                buttonText: 'Kembali ke halaman Master Density'
            });
            navigate('/admin/master-data/tinting-density', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function removeDensity(colorId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.DENSITY + colorId}/`;
        const config = {
            method: 'delete'
        };

        try {
            await axios(url, config);
            callback('Density berhasil dihapus', null);
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export default densitySlice.reducer;
