import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import CustomerLayout from 'layout/CustomerLayout';
import ProtectedRoute from './ProtectedRoute';
import { Navigate } from 'react-router-dom';

const PageNotFound = Loadable(lazy(() => import('views/pageNotFound')));
const OrderPage = Loadable(lazy(() => import('views/customer/orders')));
const ViewOrderPage = Loadable(lazy(() => import('views/customer/viewOrder')));
const ViewOrderDetailRiwayatPage = Loadable(lazy(() => import('views/customer/viewOrder/detailRiwayat')));
const FormOrder = Loadable(lazy(() => import('views/customer/form-orders')));
const FormProfile = Loadable(lazy(() => import('views/admin/form-profile')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/customer',
    element: <ProtectedRoute component={<CustomerLayout />} roles="customer" />,
    children: [
        {
            path: '/',
            element: <Navigate to="/customer/orders" />
        },
        {
            path: '/profile',
            element: <FormProfile formType="customer" />
        },
        {
            path: '/orders',
            children: [
                {
                    path: '/',
                    element: <OrderPage />
                },
                {
                    path: '/create',
                    element: <FormOrder formType="create" />
                },
                {
                    path: 'detail-riwayat/:id',
                    element: <ViewOrderDetailRiwayatPage />
                },
                {
                    path: '/:orderId',
                    element: <ViewOrderPage formType="view" />
                },
                {
                    path: '/edit/:orderId',
                    element: <ViewOrderPage formType="edit" />
                }
            ]
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]
};

export default AuthenticationRoutes;
