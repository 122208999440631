import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import endpoints from 'utils/endpoints';

const initialState = {
    isLoading: false,
    error: null,
    listOfLensa: {
        count: 0,
        results: []
    },
    viewLensa: null
};

export const lensaSlice = createSlice({
    name: 'customer-lensa',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        getLensaSuccess: (state, action) => {
            state.isLoading = false;
            if (action.payload.isInfinite) {
                state.listOfLensa.results = state.listOfLensa.results.concat(...action.payload.data.results);
                state.listOfLensa.count = action.payload.data.count;
                state.listOfLensa.next = action.payload.data.next;
            } else {
                state.listOfLensa = action.payload.data;
            }
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = lensaSlice.actions;

export function getLensa(params) {
    return async (dispatch) => {
        const url = endpoints.LENSA;
        const config = {
            method: 'get',
            params
        };

        dispatch(lensaSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(lensaSlice.actions.getLensaSuccess({ data: response, isInfinite: params?.infinite }));
        } catch (error) {
            dispatch(lensaSlice.actions.hasError(error?.response?.data));
        }
    };
}

export default lensaSlice.reducer;
