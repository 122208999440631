import { Box, Typography, Button } from '@mui/material';

export default function PageForHidden() {
    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography variant="h4">Permission Not Allowed</Typography>
            <Button component="a" href="/admin/orders">
                Kembali ke Beranda
            </Button>
        </Box>
    );
}
