import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfIndeks: {
        count: 0,
        results: []
    },
    viewIndeks: null
};

const indeksSlice = createSlice({
    name: 'indeks',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getIndeksSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfIndeks = action.payload;
        },
        getIndekSuccess: (state, action) => {
            state.isLoading = false;
            state.viewIndeks = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = indeksSlice.actions;

export function getIndeks(params) {
    return async (dispatch) => {
        const url = endpoints.INDEKS;
        const config = {
            method: 'get',
            params: {
                marketing_group: params?.marketing_group || null,
                production_type: params?.production_type || null,
                lens_type: params?.lens_type || null,
                search: params?.search || null,
                ordering: params?.ordering || null
            }
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getIndeksSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function getIndek(indexId) {
    return async (dispatch) => {
        const url = `${endpoints.INDEKS + indexId}/`;
        const config = {
            method: 'get'
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getIndekSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function createIndeks(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.INDEKS;
        const config = {
            method: 'post',
            data
        };

        dispatch(actions.startLoading());

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Indeks berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Master Indeks'
            });
            navigate('/admin/master-data/indeks', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function ubahIndeks(indexId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.INDEKS + indexId}/`;
        const config = {
            method: 'put',
            data
        };

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Indeks berhasil diubah',
                buttonText: 'Kembali ke halaman Master Indeks'
            });
            navigate('/admin/master-data/indeks', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function removeIndeks(indexId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.INDEKS + indexId}/`;
        const config = {
            method: 'delete'
        };

        try {
            await axios(url, config);
            callback('Coating berhasil dihapus', null);
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export default indeksSlice.reducer;
