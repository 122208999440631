import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //
Sentry.init({
    dsn: 'https://3f3ff9e6e29b476ea575b93dbb0d2491@o4504218239565824.ingest.sentry.io/4504922558103552',
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
});

const App = () => {
    const customization = useSelector((state) => state.customization);

    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]); // triggered on route change

    return (
        <HelmetProvider>
            <Helmet titleTemplate="%s - HOYA" defaultTitle="HOYA" />
            <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        </HelmetProvider>
    );
};

export default App;
