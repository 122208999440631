import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const PageNotFound = Loadable(lazy(() => import('views/pageNotFound')));
const PageForHidden = Loadable(lazy(() => import('views/pageforhidden')));
const AuthLensOrder = Loadable(lazy(() => import('views/customer/auth')));
const AuthDashboard = Loadable(lazy(() => import('views/admin/auth')));
const ForgotPassword = Loadable(lazy(() => import('views/forgot-password')));
const ForgotPasswordConfirm = Loadable(lazy(() => import('views/forgot-password-confirm')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ClientRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLensOrder />
        },
        {
            path: '/admin/auth',
            element: <AuthDashboard />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/password-reset-confirm',
            element: <ForgotPasswordConfirm />
        },
        {
            path: '/403',
            element: <PageForHidden />
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]
};

export default ClientRoutes;
