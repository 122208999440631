import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfGroup: {
        count: 0,
        results: []
    },
    viewGroup: null
};

const tintingGroup = createSlice({
    name: 'tintingGroup',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getColorsSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfGroup = action.payload;
        },
        getColorSuccess: (state, action) => {
            state.isLoading = false;
            state.viewGroup = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = tintingGroup.actions;

export function getTintingGroup(params) {
    return async (dispatch) => {
        const url = endpoints.TINTING_GROUP;
        const config = {
            method: 'get',
            params
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getColorsSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function getTintingGroupById(colorId) {
    return async (dispatch) => {
        const url = `${endpoints.TINTING_GROUP + colorId}/`;
        const config = {
            method: 'get'
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getColorSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function createTintingGroup(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.TINTING_GROUP;
        const config = {
            method: 'post',
            data
        };

        dispatch(actions.startLoading());

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Tinting Group berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Master Tinting Group'
            });
            navigate('/admin/master-data/tinting-group', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function ubahTintingGroup(colorId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.TINTING_GROUP + colorId}/`;
        const config = {
            method: 'put',
            data
        };

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Tinting Group berhasil diubah',
                buttonText: 'Kembali ke halaman Master Tinting Group'
            });
            navigate('/admin/master-data/tinting-group', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function removeTintingGroup(colorId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.TINTING_GROUP + colorId}/`;
        const config = {
            method: 'delete'
        };

        try {
            await axios(url, config);
            callback('Tinting Group berhasil dihapus', null);
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export default tintingGroup.reducer;
