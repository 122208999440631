/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import endpoints from 'utils/endpoints';
import JsCookie from 'js-cookie';

const initialState = {
    isLoading: false,
    error: null,
    admin: {
        accessToken: null,
        profile: null
    },
    customer: {
        accessToken: null,
        profile: null
    }
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        signInSuccess: (state, action) => {
            if (action.payload.isAdmin) {
                state.admin.accessToken = action.payload.accessToken;
            }

            if (action.payload.isCustomer) {
                state.customer.accessToken = action.payload.accessToken;
            }
        },
        signOutSuccess: (state, action) => {
            if (action.payload.isAdmin) {
                state.admin.accessToken = null;
            }

            if (action.payload.isCustomer) {
                state.customer.accessToken = null;
            }
        },
        forgotPasswordSuccess: (state) => {
            state.isLoading = false;
        },
        forgotPasswordConfirmSuccess: (state) => {
            state.isLoading = false;
        },
        getProfileSuccess: (state, action) => {
            if (action.payload.isAdmin) {
                state.admin.profile = action.payload.profile;
            }

            if (action.payload.isCustomer) {
                state.customer.profile = action.payload.profile;
            }
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export function signIn(email, password, isTypeUser, redirect) {
    return async (dispatch) => {
        const url = endpoints.AUTH;
        const config = {
            method: 'post',
            data: {
                username: email,
                password
            }
        };
        dispatch(authSlice.actions.loading());

        try {
            const response = await axios(url, config);

            if (response.data.user.is_superuser) {
                dispatch(authSlice.actions.signInSuccess({ isAdmin: true, accessToken: response.data.access }));
                await JsCookie.set('SID', response.data.access);

                return window.location.replace('/admin');
            }

            if (response.data.user.is_staff) {
                dispatch(authSlice.actions.signInSuccess({ isAdmin: true, accessToken: response.data.access }));
                await JsCookie.set('SID', response.data.access);

                return window.location.replace('/admin');
            }

            if (response.data.user.optik_group) {
                dispatch(authSlice.actions.signInSuccess({ isAdmin: true, accessToken: response.data.access }));
                await JsCookie.set('SID', response.data.access);

                return window.location.replace('/admin/orders');
            }

            if (!response.data.user.is_staff) {
                dispatch(authSlice.actions.signInSuccess({ isCustomer: true, accessToken: response.data.access }));
                await JsCookie.set('SID_CLIENT', response.data.access);

                if (redirect) {
                    window.location.href = redirect;
                } else {
                    window.location.replace('/customer');
                }
            }
        } catch (error) {
            dispatch(authSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function signOut(isTypeUser) {
    return async (dispatch) => {
        if (isTypeUser === 'admin') {
            dispatch(authSlice.actions.signOutSuccess({ isAdmin: true }));

            await JsCookie.remove('SID');
            await window.location.replace('/admin/auth');
        }

        if (isTypeUser === 'customer') {
            dispatch(authSlice.actions.signOutSuccess({ isCustomer: true }));

            await JsCookie.remove('SID_CLIENT');
            await window.location.replace('/');
        }
    };
}

export function getProfile(isTypeUser) {
    return async (dispatch) => {
        const url = endpoints.PROFILE;
        const config = {
            method: 'get',
            headers: {
                Authorization: `Bearer ${isTypeUser === 'admin' ? JsCookie.get('SID') : JsCookie.get('SID_CLIENT')}`
            }
        };
        dispatch(authSlice.actions.loading());

        try {
            const response = await axios(url, config);
            if (isTypeUser === 'admin') {
                dispatch(authSlice.actions.getProfileSuccess({ isAdmin: true, profile: response.data }));
            }

            if (isTypeUser === 'customer') {
                dispatch(authSlice.actions.getProfileSuccess({ isCustomer: true, profile: response.data }));
            }
        } catch (error) {
            dispatch(authSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function forgotPassword(data) {
    return async (dispatch) => {
        const url = endpoints.PASSWORD_RESET;
        const config = {
            method: 'post',
            data
        };
        dispatch(authSlice.actions.loading());
        try {
            const { data: response } = await axios(url, config);

            if (response?.code >= 400) {
                return dispatch(authSlice.actions.hasError({ detail: response.message, severity: 'error' }));
            }

            dispatch(authSlice.actions.forgotPasswordSuccess());
            dispatch(
                authSlice.actions.hasError({ detail: 'Email terkirim, silakan anda cek pada inbox ataupun spam', severity: 'success' })
            );
        } catch (error) {
            dispatch(authSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function forgotPasswordConfirm(data, uid, token) {
    return async (dispatch) => {
        const url = `${endpoints.PASSWORD_CONFIRM}${uid}/${token}/`;
        const config = {
            method: 'post',
            data
        };

        dispatch(authSlice.actions.loading());

        try {
            const { data: response } = await axios(url, config);

            if (response?.code >= 400) {
                return dispatch(authSlice.actions.hasError({ detail: response.message, severity: 'error' }));
            }

            dispatch(authSlice.actions.forgotPasswordConfirmSuccess());
            dispatch(
                authSlice.actions.hasError({
                    detail: 'Password anda berhasil diubah, anda akan dialihkan otomatis ke halaman login',
                    severity: 'success'
                })
            );
            setTimeout(() => {
                window.location.replace('/');
            }, 1000);
        } catch (error) {
            dispatch(authSlice.actions.hasError(error?.response?.data));
        }
    };
}

export const actions = authSlice.actions;

export default authSlice.reducer;
