import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
export default function ProtectedRoute({ component, allowedPermission, roles }) {
    const auth = useAuth(roles);
    let url = '';

    if (auth.isAuthenticated) {
        return component;
    }

    if (roles === 'admin') {
        url = `/admin/auth?redirect=${window.location.href}`;

        return <Navigate to={url} />;
    }

    url = `/?redirect=${window.location.href}`;

    return <Navigate to={url} />;
}

ProtectedRoute.propTypes = {
    component: PropTypes.any.isRequired,
    allowedPermission: PropTypes.any,
    roles: PropTypes.oneOf(['admin', 'customer']).isRequired
};
